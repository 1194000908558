import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import PropTypes from "prop-types"
import React from "react"
import * as headerStyle from "./header.module.css"

import {
  FaTwitter,
  FaRedditAlien,
  FaDiscord,
  FaFacebookF,
} from "react-icons/fa"

import Logo from "../images/mythren-logo-kickstarter.png"

const Header = ({ siteTitle }) => (
  <header className={headerStyle.header}>
    <div className={headerStyle.headerContainer}>
      <div className={headerStyle.startDiv}>
        <div className={headerStyle.leftSpace}></div>
        <h1 className={headerStyle.navLogo} style={{ margin: 0 }}>
          <Link to="/" className={headerStyle.titleText}>
            <img className={headerStyle.headerImg} src={Logo} alt="Mythren" />
          </Link>
        </h1>
      </div>
      <div className={headerStyle.centerDiv}>
        <NavBar />
        <SocialBar />
      </div>
      <div className={headerStyle.endDiv}></div>
    </div>
    <svg className={headerStyle.svg}>
      <clipPath id="logo-chunk-path" clipPathUnits="objectBoundingBox">
        <path d="m 0 0 l 1 0 v 0.454 h -0.028 l 0.013 -0.25 h -0.016 l -0.049 0.69 l -0.06 0.069 l -0.174 0.036 l -0.064 -0.093 l -0.029 0.093 h -0.593"></path>
      </clipPath>
    </svg>
  </header>
)

const NavBar = () => (
  <nav className={headerStyle.navBar}>
    <ul>
      <li className={headerStyle.navItem}>
        <AnchorLink to="/#about">About</AnchorLink>
      </li>
      <li className={headerStyle.navItem}>
        <AnchorLink to="/#news">News</AnchorLink>
      </li>
      <li className={headerStyle.navItem}>
        <AnchorLink to="/#mythren">Mythren</AnchorLink>
      </li>
      <li className={headerStyle.navItem}>
        <AnchorLink to="/#social">Social</AnchorLink>
      </li>
      <li className={headerStyle.navItem}>
        <AnchorLink to="/press">Press</AnchorLink>
      </li>
    </ul>
  </nav>
)

const SocialBar = () => (
  <div className={headerStyle.socialBar}>
    <ul>
      <li className={headerStyle.socialIcon}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/MythrenGame"
          alt="Twitter"
        >
          <FaTwitter />
        </a>
      </li>
      <li className={headerStyle.socialIcon}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/MythrenGame"
          alt="Facebook"
        >
          <FaFacebookF />
        </a>
      </li>
      <li className={headerStyle.socialIcon}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://discord.com/invite/ZwyZ7dB"
          alt="Discord"
        >
          <FaDiscord />
        </a>
      </li>
      <li className={headerStyle.socialIcon}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.reddit.com/r/Mythren/"
          alt="Reddit"
        >
          <FaRedditAlien />
        </a>
      </li>
    </ul>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
