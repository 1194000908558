/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import * as layoutStyles from "./layout.module.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main>{children}</main>
      </div>
      <div className={layoutStyles.footerShadow}>
        <footer className={layoutStyles.footer}>
          <div className={layoutStyles.footerTextbox}>
            <p className={layoutStyles.footerText}>
              © {new Date().getFullYear()} Small Leaf Studios
            </p>
            <p className={layoutStyles.footerText}>
              Website by <a href="https://twitter.com/icedog25">@icedog25</a>
            </p>
          </div>
          <svg className={layoutStyles.svg}>
            <clipPath id="footer-clip" clipPathUnits="objectBoundingBox">
              <path d="m 0 1 h 1 v -0.752 l -0.06 0 l -0.033 -0.03 l -0.012 -0.0373 l -0.089 0.0145 l -0.013 -0.0505 l -0.018 -0.006 l -0.039 0.042 l -0.02 -0.025 l -0.097 -0.018 l -0.008 -0.0343 l -0.087 -0.015 l -0.007 0.0428 l -0.195 -0.0503 l -0.01 -0.081 l -0.032 0.0715 l -0.048 -0.0085 l -0.037 0.0385 l -0.076 -0.0098 l -0.02 -0.0555 l -0.04 -0.008 l -0.059 -0.0118"></path>
            </clipPath>
            <clipPath id="footer-clip-mobile" clipPathUnits="objectBoundingBox">
              <path d="m 0 1 h 1 v -0.8347 l -0.06 0 l -0.033 -0.02 l -0.012 -0.0249 l -0.089 0.0097 l -0.013 -0.0337 l -0.018 -0.004 l -0.039 0.028 l -0.02 -0.0167 l -0.097 -0.012 l -0.008 -0.0229 l -0.087 -0.01 l -0.007 0.0285 l -0.195 -0.0335 l -0.01 -0.054 l -0.032 0.0477 l -0.048 -0.0057 l -0.037 0.0257 l -0.076 -0.0065 l -0.02 -0.037 l -0.04 -0.0053 l -0.059 -0.0079"></path>
            </clipPath>
          </svg>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
