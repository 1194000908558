// extracted by mini-css-extract-plugin
export var container = "layout-module--container--eLBMS";
export var footer = "layout-module--footer--rX0Dt";
export var footerShadow = "layout-module--footer-shadow--92E9J";
export var footerText = "layout-module--footer-text--hnjOp";
export var footerTextbox = "layout-module--footer-textbox--8tI3o";
export var heroButton = "layout-module--hero-button--UAXlh";
export var heroButtonBorder = "layout-module--hero-button-border--ekirP";
export var heroButtonText = "layout-module--hero-button-text--SvSuk";
export var heroChevron = "layout-module--hero-chevron--MF3MO";
export var heroGrid = "layout-module--hero-grid--ZBZLj";
export var heroImg = "layout-module--hero-img--FYJ88";
export var heroLinkBox = "layout-module--hero-link-box--yx4+9";
export var heroLogo = "layout-module--hero-logo--bm9UG";
export var heroSection = "layout-module--hero-section--2UWSE";
export var heroSubtitle = "layout-module--hero-subtitle--R8FD-";
export var kickstarterLogo = "layout-module--kickstarter-logo--WeQ7U";
export var svg = "layout-module--svg--PoC0A";