// extracted by mini-css-extract-plugin
export var centerDiv = "header-module--center-div--evDYP";
export var endDiv = "header-module--end-div--w1VDk";
export var header = "header-module--header--Zms1V";
export var headerContainer = "header-module--header-container--Pum4s";
export var headerImg = "header-module--header-img--rIAWN";
export var leftSpace = "header-module--left-space--bD6y3";
export var navBar = "header-module--nav-bar--EsuxO";
export var navItem = "header-module--nav-item--ByuNf";
export var navLogo = "header-module--nav-logo--C4BK4";
export var socialBar = "header-module--social-bar--5FPk9";
export var socialIcon = "header-module--social-icon--HNciq";
export var startDiv = "header-module--start-div--4SI-S";
export var svg = "header-module--svg--zEmX8";
export var titleText = "header-module--title-text--7+g5x";